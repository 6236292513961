import React from 'react'

import { Tournament as ITournament, Page } from '../lib/createPages'
import { Root, Header, Tournament } from '../components'
import { WpContextProvider } from '../contexts'
import { getJsonFromParams } from '../helpers'

interface TournamentTemplateProps {
  pageContext: { tournament: ITournament; edges: { node: Page }[] }
  location: Location
}

const TournamentTemplate: React.FC<TournamentTemplateProps> = ({ pageContext, location }) => {
  const params = getJsonFromParams<{ turnering: string; grupp: string }>(location.search.substr(1))

  return (
    <WpContextProvider tournament={pageContext.tournament}>
      <Root pageContext={pageContext}>
        <Header pageContext={pageContext}></Header>
        <Tournament tournamentId={+(params.turnering || 0)} groupId={+(params.grupp || 0)} round={+(params.rond || 0)} />
      </Root>
    </WpContextProvider>
  )
}

export default TournamentTemplate
